const BOT_TOKEN = "8010176062:AAHtb2IqtSnokZn6py9sA1x3OmuVUs212xI";
const CHAT_ID = "101458835";

/**
 * Sends a Telegram message for an error notification.
 * @param {Error|string} error - The error object or message to be sent.
 * @param {Object} errorInfo - Additional error details (optional).
 */
export const notifyErrorToTelegram = (error, errorInfo = {}) => {
  fetch(`https://api.telegram.org/bot${BOT_TOKEN}/sendMessage`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      chat_id: CHAT_ID,
      text: `⚠️ *Error Notification*\n\n*Error:* ${error.toString()}\n*Details:* ${JSON.stringify(
        errorInfo
      )}`,
      parse_mode: "Markdown",
    }),
  }).catch((err) => console.error("Failed to send error to Telegram:", err));
};

/**
 * Sends a Telegram message for a successful token purchase.
 * @param {string} buyerAddress - The name of the buyer.
 * @param {number} tokensPurchased - The number of tokens purchased.
 */
export const notifyPurchaseToTelegram = (buyerAddress, tokensPurchased) => {
  fetch(`https://api.telegram.org/bot${BOT_TOKEN}/sendMessage`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      chat_id: CHAT_ID,
      text: `🎉 *New Purchase Alert*\n\n*Buyer:* ${buyerAddress}\n*Tokens Purchased:* ${tokensPurchased}`,
      parse_mode: "Markdown",
    }),
  }).catch((err) => console.error("Failed to send purchase notification to Telegram:", err));
};
