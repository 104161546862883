import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationAR from './locales/ar/translation.json';
import translationBG from './locales/bg/translation.json';
import translationCZ from './locales/cz/translation.json';
import translationDE from './locales/de/translation.json';
import translationEL from './locales/el/translation.json';
import translationEN from './locales/en/translation.json';
import translationES from './locales/es/translation.json';
import translationFR from './locales/fr/translation.json';
import translationHU from './locales/hu/translation.json';
import translationID from './locales/id/translation.json';
import translationIT from './locales/it/translation.json';
import translationJP from './locales/jp/translation.json';
import translationKR from './locales/kr/translation.json';
import translationNL from './locales/nl/translation.json';
import translationPL from './locales/pl/translation.json';
import translationPT from './locales/pt/translation.json';
import translationRO from './locales/ro/translation.json';
import translationRU from './locales/ru/translation.json';
import translationSK from './locales/sk/translation.json';
import translationTR from './locales/tr/translation.json';
import translationVN from './locales/vn/translation.json';
import translationZHHANS from './locales/zhhans/translation.json';
import translationZHHANT from './locales/zhhant/translation.json';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      ar: {
        translation: translationAR,
      },
      bg: {
        translation: translationBG,
      },
      cz: {
        translation: translationCZ,
      },
      de: {
        translation: translationDE,
      },
      el: {
        translation: translationEL,
      },
      en: {
        translation: translationEN,
      },
      es: {
        translation: translationES,
      },
      fr: {
        translation: translationFR,
      },
      hu: {
        translation: translationHU,
      },
      id: {
        translation: translationID,
      },
      it: {
        translation: translationIT,
      },
      jp: {
        translation: translationJP,
      },
      kr: {
        translation: translationKR,
      },
      nl: {
        translation: translationNL,
      },
      pl: {
        translation: translationPL,
      },
      pt: {
        translation: translationPT,
      },
      ro: {
        translation: translationRO,
      },
      ru: {
        translation: translationRU,
      },
      sk: {
        translation: translationSK,
      },
      tr: {
        translation: translationTR,
      },
      vn: {
        translation: translationVN,
      },
      zh_hans: {
        translation: translationZHHANS,
      },
      zh_hant: {
        translation: translationZHHANT,
      },
    },
    fallbackLng: 'en',
    debug: true,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;