const privacyPolicyText = `
Overview:
At PuppyShibaInu.com, safeguarding your privacy is our top priority. This privacy policy outlines the type of information we gather from you and how we utilize it. Rest assured, we never disclose your private data to external entities.

Data Collection:

The information we collect is geared towards enhancing our services and includes:
Your Name and Email - Provided at your discretion.
Technical Details - Such as IP address and browser specifics.
Crypto Information - Like your wallet addresses.

Data Utilization - We employ this data to:
Optimize website functionality to suit your needs.
Ensure robust security measures are in place.
Gain insights to refine and enhance user experience.

Data Security Measures - We maintain stringent protocols to protect your data:

Data Retention:
We only retain information for as long as necessary, after which it's promptly deleted.
No Third-Party Sharing.
Rest assured, we never share your data with external entities.

Get in Touch:
For any inquiries or assistance, feel free to reach out to us at contact@puppyshibainu.com.`;

export { privacyPolicyText };



const cookieText = `
At PuppyShibaInu.com, we strive to enrich your journey through the decentralized and crypto-focused realm of our platform. By continuing to explore our site, you agree to our utilization of cookies.

Understanding Cookies:
Cookies are small text files stored on your device upon accessing our website. They help us recall specific details about your visit, such as preferred settings, enhancing your experience within the crypto landscape.

Utilization of Cookies:
Essential Cookies:
These cookies are vital for the proper functioning of our platform. They ensure seamless operation of certain blockchain features.

Performance Cookies:
These cookies aid in analyzing user interactions with our crypto functionalities, facilitating enhancements to site performance.

Third-Party Cookies:
We may employ third-party cookies to offer supplementary crypto-related services like embedded exchange tools or social media integrations. Such cookies are managed by respective third-party providers and may track your activities across other platforms.

Crypto Cookies:
These cookies enable us to furnish personalized cryptocurrency encounters, including the retention of your wallet address or preferred tokens.

Managing Your Cookies
You have the ability to manage or delete cookies through your browser settings. However, disabling essential cookies may impact the functionality of specific crypto-related features on our platform.

Questions and Clarifications:
For any inquiries or clarifications regarding our cookie usage or policies within the crypto sphere, please reach out to us at contact@puppyshibainu.com.

By interacting with PuppyShibaInu.com, you acknowledge and consent to our utilization of cookies in accordance with this Cookies Policy.`;

export { cookieText };

